import "./BuyerJobs.css";

import React, { useState } from "react";
import { Col,Container, Row } from "react-bootstrap";

import CurrentJob from "../CurrentJob/CurrentJob";
import PastJob from "../PastJob/PastJob";

const BuyerJobs = () => {
  const [showCurrentJobs, setShowCurrentJobs] = useState(true);

  // const handleJobsView=()=>{
  //   setShowCurrentJobs(tr)
  // }
  return (
    <div className="Font_Family theme_text_color buyer_job_main_div">
      <Container className="buyer_job_row_style mt-5">
        <Row className="buyer_job_row_style">
          <Col
            xxl={10}
            xl={10}
            lg={10}
            md={10}
            sm={12}
            xs={12}
            style={{
              borderBottom: "1px solid #a7a9ac",
              paddingBottom: "6px",
              height: "30px",
            }}
          >
            <div>
              <span
                onClick={() => setShowCurrentJobs(true)}
                className={`${
                  showCurrentJobs ? "buyer_job_active" : "buyer_job_inactive"
                }`}
              >
                CURRENT JOBS
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span
                onClick={() => setShowCurrentJobs(false)}
                className={`${
                  showCurrentJobs ? "buyer_job_inactive" : "buyer_job_active"
                }`}
              >
                PAST JOBS
              </span>
            </div>
          </Col>
          <Col
            xl={10}
            lg={10}
            md={10}
            sm={12}
            xs={12}
            // className="buyer_job_row_style"
            style={{ height: "100vh", marginTop: "0px", marginBottom: "100px" }}
          >
            {/* <Tab.Container activeKey={activeKey} onSelect={setActiveKey}>
              <Nav
                variant="tabs"
                className="d-flex align-items-center buyer_job_nav_style"
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey={CURRENT_JOB_KEY}
                    className="theme_text_color"
                  >
                    CURRENT JOBS
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={PAST_JOB_KEY}
                    className="theme_text_color"
                  >
                    PAST JOBS
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey={CURRENT_JOB_KEY}>
                  <CurrentJob />
                </Tab.Pane>
                <Tab.Pane eventKey={PAST_JOB_KEY}>
                  <PastJob />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container> */}
            {showCurrentJobs ? <CurrentJob /> : <PastJob />}
          </Col>
          {/* <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <div style={{ width: "100%", height: "100px" }}></div>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default BuyerJobs;
