import "./pastrating.css";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import downarrow from "../../../assets/icons/downarrow.webp";
import uparrow from "../../../assets/icons/uparrow.webp";
import { R8tingCategoryDamagesAction } from "../../../redux/actions/R8tr/R8tingCategoryDamagesAction";
import { R8tingCategoryListAction } from "../../../redux/actions/R8tr/R8tingCategoryListAction";
import CriteriaOption from "../CriteriaOption/CriteriaOption";
function PastRating({ jobData }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openedCriteriaId, setOpenedCriteriaId] = useState([]);
  const [criteriaList, setCriteriaList] = useState([]);
  const { R8tingCategoryList } = useSelector(
    (state) => state.R8tingCategoryListReducer
  );
  const { R8tingCategoryDamages } = useSelector(
    (state) => state.R8tingCategoryDamagesReducer
  );
  useEffect(() => {
    setLoading(true);
    dispatch(R8tingCategoryListAction(jobData.id));
    // dispatch(R8tingCategoryListAction(687));
  }, []);
  useEffect(() => {
    if (R8tingCategoryList?.ratingCriterias?.length > 0) {
      for (let i = 0; i < R8tingCategoryList?.ratingCriterias?.length; i++) {
        let ratedArray = [];
        for (
          let j = 0;
          j < R8tingCategoryList?.ratingCriterias[i].options?.length;
          j++
        ) {
          if (
            R8tingCategoryList?.ratingCriterias[i].options[j].type ==
              "Option" &&
            R8tingCategoryList?.ratingCriterias[i].options[j]
              .ratingCriteriaUser !== null
          ) {
            ratedArray.push(R8tingCategoryList?.ratingCriterias[i].options[j]);
            R8tingCategoryList.ratingCriterias[i].totalRated =
              R8tingCategoryList?.ratingCriterias[i].options[j].ratingValue;
            break;
          } else if (
            R8tingCategoryList?.ratingCriterias[i].options[j].type !==
              "Option" &&
            R8tingCategoryList?.ratingCriterias[i].options[j]
              .ratingCriteriaUser !== null
          ) {
            ratedArray.push(R8tingCategoryList?.ratingCriterias[i].options[j]);
            R8tingCategoryList.ratingCriterias[i].totalRated = "Rated";
            break;
          }
        }
      }
      setCriteriaList(R8tingCategoryList?.ratingCriterias);
    }
    setLoading(false);
  }, [R8tingCategoryList]);
  const handleR8tingOptionsList = (id) => {
    if (openedCriteriaId == id) {
      setOpenedCriteriaId(null);
    } else {
      setOpenedCriteriaId(id);
    }
    // dispatch(R8tingCategoryDataAction(optionId));
    dispatch(R8tingCategoryDamagesAction(id, jobData.id));
  };
  return (
    <div style={{ marginTop: "90px" }}>
      <Container className="r8ting_main_container">
        <Row className="r8ting_main_row">
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="r8ting_main_col"
          >
            <span
              className="Font_Family theme_text_color h4 r8ting_title_span w-100 text-uppercase"
              style={{ fontSize: "20px" }}
            >
              {/* {jobData.make} {jobData.name}-{jobData.model} */}
              RATING
            </span>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="r8ting_main_col"
          >
            <div className="vehical_sep_div">
              {/* <img
                src={jobData.category.icon}
                alt="icon"
                style={{ width: "30px", height: "30px" }}
              /> */}
              <svg
                viewBox="0 0 100 100"
                version="1.1"
                xmlns={jobData.category.icon}
                style={{ marginRight: "10px" }}
                className="siglejob_svg"
              >
                <defs>
                  <pattern
                    id="img"
                    patternUnits="userSpaceOnUse"
                    width="100"
                    height="100"
                  >
                    <image
                      xlinkHref={jobData.category.icon}
                      x="-25"
                      width="250"
                      height="100"
                    />
                  </pattern>
                </defs>
                <polygon
                  points="50 1 95 25 95 75 50 99 5 75 5 25"
                  fill="url(#img)"
                />
              </svg>

              <p className="theme_text_color Font_Family text-uppercase font-weight-bold">
                <span
                  className="theme_text_color fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  PRICE
                </span>
                <br />
                <span
                  className="theme-color fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  ${jobData.askingPrice}
                </span>
              </p>

              <p className="theme_text_color Font_Family text-uppercase">
                <span
                  className="theme_text_color fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  MILES
                </span>
                <br />
                <span
                  className="silver_Color fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  {jobData.zip}
                </span>
              </p>

              <p className="theme_text_color Font_Family text-uppercase">
                <span
                  className="theme_text_color fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  CUSTOMER MICHAEL
                </span>
                <br />
                <span
                  className="silver_Color fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  {jobData.contactNumber}
                </span>
              </p>
            </div>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="r8ting_main_col"
          >
            <div className="R8tingCategoryList_tabs_container">
              {criteriaList?.map((item) => (
                <div key={item.id}>
                  <ul
                    className={` R8tingCategoryList_tabs Font_Family ${
                      openedCriteriaId == item.id
                        ? "R8tingCategoryList_tabs_Active "
                        : "R8tingCategoryList_tabs_color"
                    }`}
                    onClick={() => {
                      handleR8tingOptionsList(item.id);
                    }}
                  >
                    <li
                      className="R8tingCategoryList_tabs_li text-uppercase theme_text_color fw-bold"
                      style={{ paddingLeft: "10px", fontSize: "14px" }}
                    >
                      {item.title}
                    </li>

                    <li className="R8tingCategoryList_tabs_li2">
                      <p
                        style={{
                          display: "inline",
                          justifyContent: "start",
                          alignItems: "self-start",
                        }}
                      >
                        {item.totalRated ? item?.totalRated : "Not Rated"}
                      </p>
                      {openedCriteriaId == item.id ? (
                        <img
                          src={uparrow}
                          alt="uparrow"
                          style={{ width: "15px", height: "10px" }}
                        />
                      ) : (
                        <img
                          src={downarrow}
                          alt="downarrow"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </li>
                  </ul>
                  {openedCriteriaId && openedCriteriaId == item.id && (
                    <CriteriaOption
                      ratingCriteriaOptions={item.options}
                      damages={R8tingCategoryDamages[0]?.damages}
                      ratingCriteria={item}
                      includeCommonImages={item?.includeCommonImages}
                      includeCriteriaNotes={item?.includeCriteriaNotes}
                    />
                  )}
                </div>
              ))}
            </div>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="r8ting_main_col my-4"
            style={{ justifyContent: "center" }}
          >
            {/* <button
              className="btn  w-25 Font_Family"
              style={{
                height: "45px",
                borderRadius: "0px 15px 0px 15px",
                backgroundColor: "#e61d2a",
                color: "white",
              }}
              onClick={() => {
                navigate("/uploaded-videos", { state: { jobData: jobData } });
              }}
            >
              <span className="d-flex justify-content-center align-content-center w-100">
                NEXT
              </span>
            </button> */}
          </Col>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Row>
      </Container>
    </div>
  );
}

export default PastRating;
